<template>
    <div class="mct-form container">
        <mct-topo-lista :titulo="titulo" :subtitulo="'Manutenção de '+ titulo + ' - ' + modo.toUpperCase()"></mct-topo-lista>    
        <mct-alertas :mensagem="message" :errors="errors" :status="status" :modo="modo" v-show="status !== 0"></mct-alertas>
        <div class="container-fluid">
            <mct-form>
                <slot name="formulario"></slot>    
            </mct-form>
        </div>
        <mct-form-botoes @submit="submit()" :modo="modo"></mct-form-botoes>
    </div>
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import MctTopoLista from './MctTopoLista.vue'
import MctForm from './MctForm.vue'
import MctFormBotoes from './MctFormBotoes.vue'
import MctAlertas from './MctAlertas.vue' 
import MctMixinsForm from '@/components/funcoes/MctMixinsForm.js'

export default {
    Name: 'FormPadraoSC', 
    mixins: [MctMixinsForm], 
    components: {
        MctForm,
        MctTopoLista,
        MctFormBotoes,
        MctAlertas,
    },
    props: ['source','titulo','modo','listaAdicional'],
    data () {
        return{
        }
    },
    methods: {
        submit(){
            this.$emit('submit')
        },
        confirma (){
            this.$emit('confirma')
        }                
  },
  mounted() {

  } 
}
</script>


